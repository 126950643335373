<template>
  <div class="app">
    <div class="app-body">
      <main class="main">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
  import nav from '@/_nav'

  export default {
    name: 'EmptyContainer',
    components: {
    },
    data () {
      return {
        nav: nav.items
      }
    },
    computed: {
      name () {
        return this.$route.name
      },
      list () {
        return this.$route.matched.filter((route) => route.name || route.meta.label )
      }
    }
  }
</script>

<style type="text/css">
  html, body{
    height: 100%;
  }

  body {
    background-image: url("/img/background.jpg") ;
    background-position: center center;
    background-repeat:  no-repeat;
    background-attachment: fixed;
    background-size:  cover;
    background-color: #999;
  }
  div, body{
    margin: 0;
    padding: 0;
    font-family: exo, sans-serif;

  }

  .app {
    height: 100%;
    width: 100%;
  }



</style>
